import * as Sentry from '@sentry/vue'

import type { CaptureContext } from '@sentry/types'
import type { App } from 'vue'

import { environment } from '@/envinronment'
import router from '@/router'
import vendorConfig from '@/vendorConfig'

const ignoredErrors: string[] = [
  "Can't find variable: _AutofillCallbackHandler",
  'The user aborted a request.',
  'Fetch is aborted',
]

class ErrorTrackingService {
  init(app: App): void {
    if (window.location.host.includes('localhost')) {
      return
    }

    Sentry.init({
      app,
      dsn: vendorConfig.sentry.dsn,
      integrations: [
          Sentry.browserTracingIntegration({
            router
          }),
        Sentry.replayIntegration(),

      ],
      environment: environment.APP_ENV,
      tracesSampleRate: 0.03,
      replaysSessionSampleRate: 0.03,
      replaysOnErrorSampleRate: 0.3,
      beforeSend(event: Sentry.ErrorEvent, hint: Sentry.EventHint): null | Sentry.ErrorEvent {
        console.error(event)

        if (hint?.originalException instanceof Error) {
          const errorMessage = hint.originalException.message
          const hasError = ignoredErrors.some((ignoredError) => errorMessage.includes(ignoredError))

          if (hasError) {
            return null
          }
        }

        return event
      },
    })
  }

  configureScope({
    user,
    utmSource = null,
  }: {
    user: { id: string; email: string }
    utmSource?: string | null
  }): void {
    Sentry.setUser(user)
    Sentry.setTag('utmSource', utmSource)
  }

  captureMessage(message: string, error: CaptureContext): void {
    console.error(message, error)

    Sentry.captureMessage(message, error)
  }

  captureException(exception: any): void {
    console.error(exception)

    Sentry.captureException(exception)
  }
}

export default new ErrorTrackingService()
