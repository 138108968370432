import './assets/main.css'

import { DefaultApolloClient } from '@vue/apollo-composable'
import { createPinia } from 'pinia'
import { createApp, provide, h } from 'vue'
import { createVfm } from 'vue-final-modal'

import App from './App.vue'
import router from './router'

import {apolloClient} from "@/api/ApolloClient";
import authService from "@/services/AuthService";
import ErrorTrackingService from "@/services/ErrorTrackingService";
import TrackingService from "@/services/TrackingService";

const vfm = createVfm()

const app = createApp({
    setup () {
        provide(DefaultApolloClient, apolloClient)
    },

    render: () => h(App),
})

ErrorTrackingService.init(app)
TrackingService.init()
authService.init()

app.use(createPinia())
app.use(router)
app.use(vfm)

app.mount('#app')
