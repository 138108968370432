import { defineStore } from 'pinia'

type User = {
  email: string
}
type UserStore = {
  user: User
}

export const useUserStore = defineStore('user', {
  state: (): UserStore => ({
    user: {
      email: ''
    },
  }),
  actions: {
    setEmail(email: string) {
      this.user.email = email
    }
  }
})