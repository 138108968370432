import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: 'subscriptions'
    },
    {
      path: '/subscriptions',
      component: () => import('@/components/subscription/Layout.vue'),
      children: [
        {
          path: '',
          name: 'subscriptions',
          component: () => import('@/pages/subscriptions/Subscriptions.vue')
        },
        {
          path: 'feedback',
          name: 'feedback',
          component: () => import('@/pages/subscriptions/FeedbackTeaser.vue')
        },
        {
          path: 'reason-to-cancellation',
          name: 'reason',
          component: () => import('@/pages/subscriptions/ReasonToCancel.vue')
        },
        {
          path: 'feedback-form',
          name: 'feedback-form',
          component: () => import('@/pages/subscriptions/FeedbackForm.vue')
        },
        {
          path: 'cancellation-confirm',
          name: 'cancellation-confirm',
          component: () => import('@/pages/subscriptions/CancellationConfirm.vue')
        }
      ]
    }
  ]
})

export default router
