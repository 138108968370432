import { v4 as uuidv4 } from 'uuid'

import type { ApiGenericAsync } from '@/types/api'

import apiClient from '@/api/ApiClient'
import ErrorTrackingService from '@/services/ErrorTrackingService'

const LIVEN_SESSION_ID = 'liven_session_id'

export enum TrackEventName {
  CancellationStarted = 'cancellation_started',
  ScreenView = 'screen_view',
  QuestionAnswer = 'question_answer',
  FinalDecision = 'final_decision'
}

export const AnalyticsTrackScreenNamesMap = {
  MySubscription: 'My Subscription',
  ProvideFeedbackTeaser: 'Provide Feedback Teaser',
  ReasonToCancel: 'Reason to Cancel',
  ExtraFeedbackForm: 'Extra Feedback Form',
  CancellationConfirm: 'Cancellation Confirm'
}

export const TrackEventParamsMap = {
  ScreenName: 'screen_name',
  QuestionAnswer: 'question_answer',
  QuestionName: 'question_name'
}

export enum TrackEventAction {
  Click = 'Click',
  Visit = 'Visit'
}

// Tracking service event example
// TrackingService.trackEvent(TrackEventName.QuestionAnswer, TrackEventAction.Click, {
//   [TrackEventParams.ScreenName]: AnalyticsTrackScreenNamesMap.ReasonToCancel,
//   [TrackEventParams.QuestionAnswer]: 'Lack of functionality',
//   [TrackEventParams.QuestionName]: 'What is the main reason you decided not to continue with Liven?'
// })

class TrackingService {
  // @ts-expect-error
  private sessionId: string

  init(): void {
    const idFromStorage = sessionStorage.getItem(LIVEN_SESSION_ID)

    if (!idFromStorage) {
      sessionStorage.setItem(LIVEN_SESSION_ID, uuidv4())
    }

    this.sessionId = sessionStorage.getItem(LIVEN_SESSION_ID) || ''
  }

  trackEvent(
    eventName: TrackEventName,
    eventAction: TrackEventAction = TrackEventAction.Click,
    params?: Record<string, any>
  ): ApiGenericAsync<unknown> {
    if (!eventName) {
      ErrorTrackingService.captureMessage(`Event name is empty`, {})
    }

    return apiClient.analyticsTrack({
      type: 'cancellation_flow',
      name: eventName,
      action: eventAction,
      createdAt: new Date().getTime(),
      eventId: uuidv4(),
      sessionId: this.sessionId,
      eventProperties: params
    })
  }
}

export default new TrackingService()
