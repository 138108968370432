import type { ApiGenericAsync, ServerApiError } from '@/types/api'

import authService from '@/services/AuthService'
import errorTrackingService from '@/services/ErrorTrackingService'

export default class HttpClient {
  url: string
  requestTimeout: number

  constructor(url: string, requestTimeout: number = 30_000) {
    this.url = url
    this.requestTimeout = requestTimeout
  }

  get headers(): Record<string, string> {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${authService.userToken}`
    }
  }

  async get<T, Err = ServerApiError>(endpoint: string, data?: any): ApiGenericAsync<T, Err> {
    const url = new URL(endpoint, this.url)
    const queryParams = new URLSearchParams(data)
    const urlWithParams = `${url}${data ? '?' : ''}${queryParams.toString()}`
    const controller = new AbortController()
    const options = {
      method: 'GET',
      headers: this.headers,
      signal: controller.signal
    }
    const timeoutId = setTimeout(() => controller.abort(), this.requestTimeout)

    try {
      const response = await fetch(urlWithParams, options)
      const data = await response.json()

      clearTimeout(timeoutId)

      if (response.status >= 400) {
        throw response
      }

      return [data, null]
    } catch (err: any) {
      this.trackError(err, data, 'GET', endpoint)

      return [null, err]
    }
  }

  async post<T, Err = ServerApiError>(endpoint: string, data?: any): ApiGenericAsync<T, Err> {
    const url = new URL(endpoint, this.url)
    const controller = new AbortController()
    const options = {
      method: 'POST',
      headers: this.headers,
      signal: controller.signal,
      body: JSON.stringify(data)
    }
    const timeoutId = setTimeout(() => controller.abort(), this.requestTimeout)

    try {
      const response = await fetch(url, options)
      const data = await response.json()

      clearTimeout(timeoutId)

      if (response.status >= 400) {
        throw data
      }

      return [data, null]
    } catch (err: any) {
      this.trackError(err, data, 'POST', endpoint)

      return [null, err]
    }
  }

  private trackError(err: any, data: any, method: 'GET' | 'POST', endpoint: string = ''): void {
    console.error(err)

    if (
      (err.message === 'Failed to fetch' || err.message === 'Load failed') &&
      // facebook page view events
      data?.eventName !== 'PageView'
    ) {
      errorTrackingService.captureMessage(`API network error`, {
        extra: {
          data,
          err,
          request: {
            endpoint,
            method
          }
        }
      })
    } else {
      errorTrackingService.captureMessage(`API ERROR: ${endpoint}`, {
        extra: {
          data,
          err,
          request: {
            method,
            body: data
          }
        }
      })
    }
  }
}
