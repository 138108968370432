import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'

import { environment } from '@/envinronment'
import authService from '@/services/AuthService'

const httpLink = createHttpLink({
  uri: environment.GRAPHQL_API_URL
})

const cache = new InMemoryCache()

const authLink = setContext(async (_, { headers }) => {
  const token = authService.userToken

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache
})
