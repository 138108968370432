import { initializeApp } from '@firebase/app'
import { getAuth, signInWithCustomToken } from '@firebase/auth'
import gql from 'graphql-tag'

import type { FirebaseApp } from '@firebase/app'
import type { Auth } from '@firebase/auth'

import { apolloClient } from '@/api/ApolloClient'
import { environment } from '@/envinronment'
import ErrorTrackingService from '@/services/ErrorTrackingService'
import { useUserStore } from '@/stores/user'

const LIVEN_USER_TOKEN = 'liven_user_token'

class AuthService {
  // @ts-expect-error
  app: FirebaseApp
  // @ts-expect-error
  authClient: Auth

  init(): void {
    this.app = initializeApp({
      apiKey: environment.FIREBASE_API_KEY
    })
    this.authClient = getAuth(this.app)

    this.authClient.onAuthStateChanged(async (user) => {
      if (user) {
        useUserStore().setEmail(user.email || '')

        const token = (await user?.getIdToken()) || ''

        sessionStorage.setItem(LIVEN_USER_TOKEN, token)
      } else {
        sessionStorage.removeItem(LIVEN_USER_TOKEN)
      }
    })
  }

  async signInWithToken(link: string): Promise<void> {
    if (this.userToken) {
      return
    }

    const tokenId = this.getUserTokenId(link)

    if (!tokenId) {
      ErrorTrackingService.captureMessage("The user's tokenId was not provided ", {})

      return
    }

    const { data } = await apolloClient.query({
      query: gql`
        query customToken($tokenId: String!) {
          customToken(tokenId: $tokenId)
        }
      `,
      variables: {
        tokenId
      }
    })

    try {
      await signInWithCustomToken(this.authClient, data.customToken)
    } catch (e: unknown) {
      ErrorTrackingService.captureMessage('Error while login by link', {
        extra: {
          error: e
        }
      })
    }
  }

  getUserTokenId(link: string): string {
    const url = new URL(link)
    const urlParams = new URLSearchParams(url.search)

    return urlParams.get('tokenId') || ''
  }

  get userToken(): string {
    return sessionStorage.getItem(LIVEN_USER_TOKEN) || ''
  }
}

export default new AuthService()
