import HttpClient from './HttpClient'

import type {
  AnalyticsTrackReq,
} from '@/types/api'
import type {ApiGenericAsync} from "@/types/api";

import { environment } from '@/envinronment'

export class ApiClient {
  http: HttpClient
  constructor() {
    this.http = new HttpClient(environment.HTTP_API_URL)
  }


  analyticsTrack(data: AnalyticsTrackReq): ApiGenericAsync<unknown> {
    return this.http.post('analytics/track-web', {items: [data]})
  }
}

export default new ApiClient()
